<template>
    <modal :id="id" :title="title" :persistent="false">
        <template #default>
            <v-container class="px-0 video-template-preview">
                <v-row no-gutters>
                    <v-col v-if="isLoading" cols="6">
                        <v-skeleton-loader
                            loading
                            type="article"
                            class="ml-n4"
                        />
                    </v-col>
                    <v-col v-else cols="6">
                        <p>{{ selected.description }}</p>
                        <div v-if="templates.length > 1">
                            <h3>Select Duration:</h3>
                            <v-radio-group
                                v-model="selectedId"
                                dense
                                hide-details
                            >
                                <v-radio
                                    v-for="(option, key) in templates"
                                    :key="key"
                                    :value="key"
                                    class="mb-4"
                                    @change="variation(key)"
                                >
                                    <template #label>
                                        <span
                                            class="ml-n8 d-flex align-center white"
                                        >
                                            <v-icon
                                                left
                                                small
                                                :color="
                                                    key === selectedId
                                                        ? 'primary'
                                                        : 'secondary'
                                                "
                                            >
                                                clock
                                            </v-icon>
                                            {{ duration(option) }}
                                        </span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </div>
                        <div v-else>
                            <h3>Duration:</h3>
                            <div class="d-flex align-center py-2">
                                <v-icon small left>clock</v-icon>
                                {{ duration(selected) }}
                            </div>
                        </div>
                        <div class="pt-4">
                            <h4>You will need:</h4>
                            <div class="d-flex align-center py-2">
                                <v-icon small left>align-left</v-icon>
                                {{ captions(selected) }}
                            </div>
                            <div
                                v-if="mediaCount(selected)"
                                class="d-flex align-center py-2"
                            >
                                <v-icon small left>image</v-icon>
                                {{ medias(selected) }}
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <v-img :src="previewSrc" min-height="200" />
                        <div v-if="hasSamples" class="mt-2">
                            <h3>Sample Videos</h3>
                            <ul>
                                <li v-for="(sample, key) in samples" :key="key">
                                    <a :href="sample.url" target="_blank">
                                        {{ getSampleTitle(sample) }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template #actions>
            <v-btn
                large
                text
                :block="$vuetify.breakpoint.smAndDown"
                @click="close"
            >
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                large
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="isLoading"
                @click="select"
            >
                Select template
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { Modal } from '@/components/Modal';

import { Endpoint } from '@/mixins';
import type { SlideshowTemplate } from '@/types/Slideshow';
import type { VideoTemplate } from '@/types/Video';
import type { VideoDistributionSiteEvent } from '@/types/VideoDistributionSiteEvent';

const AVideoTemplatePreviewProps = Vue.extend({
    name: 'AVideoTemplatePreview',
    props: {
        template: {
            type: Object as PropType<SlideshowTemplate>,
            default() {
                return {
                    id: 0,
                    name: '',
                    image: ''
                };
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class AVideoTemplatePreview extends mixins(
    Endpoint,
    AVideoTemplatePreviewProps
) {
    id = 'amp-video-template-preview';

    endpoint = '/video_prs/add?announcement_id';

    templates: VideoTemplate[] = [];
    samples: VideoDistributionSiteEvent[] = [];

    selectedId = 0;

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return `${this.endpoint}=${this.announcementId}&parent_template_id=${this.template.id}`;
    }

    get title() {
        return this.selected?.name || this.template.name;
    }

    get image() {
        return this.selected?.image || this.template.image;
    }

    get previewSrc() {
        return `/video/templates/${this.image}`;
    }

    get selected() {
        return this.templates[this.selectedId];
    }

    get hasSamples() {
        return this.samples.length;
    }

    @Watch('template')
    onTemplateChange(template: SlideshowTemplate) {
        if (template?.id) {
            this.reset();

            this.load();
        }
    }

    onData(data: {
        templates: VideoTemplate[];
        samples: VideoDistributionSiteEvent[];
    }) {
        this.templates = data.templates;
        this.samples = data.samples;
    }

    reset() {
        this.selectedId = 0;
        this.templates = [];
        this.samples = [];
    }

    select() {
        this.$emit('select', this.selected);

        this.close();
    }

    variation(id: number) {
        this.selectedId = id;
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }

    duration(template: VideoTemplate) {
        const duration = template.video_boxes.reduce((acc, box) => {
            const current = box.start_frame + box.duration;

            const perHour = current / 24;

            if (perHour > acc) {
                return Math.round(perHour * Math.pow(10, 0)) / Math.pow(10, 0);
            }

            return acc;
        }, 0);

        const mins = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return (
            (mins > 0 ? `${mins} Minute${mins > 1 ? 's' : ''} ` : '') +
            (seconds > 0 ? `${seconds} Second${seconds > 1 ? 's' : ''}` : '')
        );
    }

    captions(template: VideoTemplate) {
        const captions = template.video_boxes.reduce((acc, box) => {
            if (box.video_text_box) {
                acc++;
            }

            return acc;
        }, 0);

        return `${captions} Caption${captions > 1 ? 's' : ''}`;
    }

    medias(template: VideoTemplate) {
        const mediaCount = this.mediaCount(template);

        return `${mediaCount} Image${mediaCount > 1 ? 's' : ''} or Video${
            mediaCount > 1 ? 's' : ''
        }`;
    }

    mediaCount(template: VideoTemplate) {
        return template.video_boxes.reduce((acc, box) => {
            if (
                Number(box.video_media_box?.allow_user_images) > 0 &&
                !box.video_media_box?.is_logo_box
            ) {
                acc++;
            }

            return acc;
        }, 0);
    }

    getSampleTitle(sample: VideoDistributionSiteEvent) {
        return sample.video_pr?.title || '';
    }
}
</script>

<style lang="scss" scoped>
.video-template-preview::v-deep {
    .v-label {
        color: $secondary-color;
    }
}
</style>
